export const API_PATH = {
  activityLog: {
    logsForActiveClient: 'activity-logs/logs-for-active-client',
    logsByLease: 'activity-logs/find-activities-by-lease',
    logsByProperty: 'activity-logs/find-activities-by-property',
  },
  client: {
    updateActiveClient: 'clients/update-current-client',
    getClientsWithUserClients: `clients/get-clients-with-user-clients`,
    createOne: `clients/create-one/`,
    updateOne: `clients/update-one/`,
    deleteOne: `clients/delete-one/`,
  },
  lease: {
    findAll: 'leases/find-by-active-client',
    findAllWithFinancials: 'leases/leases-with-financials',
    findOne: 'leases/get-by-id',
    findAllByPropertyId: 'leases/find-by-property',
    findAllByPropertyIdExcludeSpaces: 'leases/find-by-property-exclude-spaces',
    create: 'leases/create',
    update: 'leases/update',
    remove: 'leases/remove',
    findByText: 'leases/find-by-search-text',
    leaseAbstractReport: 'leases/lease-abstract-report',
    findSpacesByLease: 'leases/find-spaces-by-lease',
    leaseFinancials: 'leases/lease-financials',
    findLeasesWithMissingPayments: 'leases/find-leases-with-missing-payments',
  },
  leaseCategory: {
    getAll: 'lease-category/find-all',
    createOne: 'lease-category/create-one',
    deleteOne: 'lease-category/delete-one',
    updateMany: 'lease-category/update-many',
  },
  planOfAction: {
    createOne: 'plan-of-action/create-one',
    updateMany: 'plan-of-action/update-many',
    deleteOne: 'plan-of-action/delete-one',
  },
  note: {
    findAllByClient: 'note/find-all-by-client',
    findAllByNoteObjectTypeAndResourceIdentifier:
      'note/find-all-by-note-object-type-and-resource-identifier',
    create: 'note/create-one',
    update: 'note/update-one',
    delete: 'note/delete-one',
  },
  clause: {
    findAllByLeaseId: 'clauses/get-by-lease',
    create: 'clauses/create',
    update: 'clauses/update',
    remove: 'clauses/remove',
  },
  clauseType: {
    list: 'clause-types/find-all',
    getDropdown: 'clause-types/get-dropdown',
  },
  property: {
    getPropertiesWithLeases: 'properties/get-properties-with-leases',
    getPropertiesWithAllRelations: 'properties/get-properties-with-all-relations',
    getPropertiesReport: 'properties/report',
    getOne: 'properties/get-one',
    create: 'properties/create',
    update: 'properties/update',
    remove: 'properties/remove',
    getRelatedEntities: 'properties/get-related-entities',
  },
  report: {
    all: 'report/all',
    render: 'report/render',
    updateClientReport: 'report/update-client-report',
    updateClientReportFormat: 'report/update-client-report-format',
    updateClientReportFilter: 'report/update-client-report-filter',
  },
  jsreport: {
    currencyExchangeRateReport: 'jsreport/currency-exchange-rate-report',
  },
  user: {
    getUsersWithUsersClients: 'users/get-users-with-clients',
    getUserInfo: 'users/get-user-info',
    getAdditionalInfoForUser: 'users/get-additional-info-for-user',
    createUserWithUserClients: 'users/create-users-with-clients',
    updateUserWithUserClients: 'users/update-users-with-clients',
    updateUserPreferences: 'users/update-user-preferences',
    updateUserActiveClient: 'users/update-user-active-client',
    deleteOne: `users/delete-one/`,
  },
  userClient: {
    findOneByClient: 'user-client/find-one-by-client',
  },
  country: {
    list: 'countries',
  },
  marketMetro: {
    getAll: 'market-metros/find-all',
    createOne: 'market-metros/create-one',
    deleteOne: 'market-metros/delete-one',
    updateMany: 'market-metros/update-many',
  },
  ownershipType: {
    list: 'ownership-types',
  },
  propertyStatus: {
    list: 'property-statuses',
  },
  propertyType: {
    list: 'property-types/find-all',
    getPropertyTypesByActiveClient: 'property-types/find-by-active-client',
    delete: 'property-types/delete',
    updateMany: 'property-types/update-many',
    create: 'property-types/create',
  },
  payment: {
    findByPeriod: 'payments/find-by-period',
    findByLease: 'payments/find-by-lease',
    generateByPeriod: 'payments/generate-by-period',
    regenerate: 'payments/regenerate',
    findDistinctPeriods: 'payments/find-distinct-periods',
    update: 'payments/update',
    createDetailWithTaxes: 'payments/create-detail-with-taxes',
    updateDetail: 'payments/update-detail',
    deleteDetail: 'payments/delete-detail',
  },
  region: {
    list: 'regions/find-all',
    getRegionsByActiveClient: 'regions/find-by-active-client',
    findOne: 'regions/find-one',
    findFist: 'regions/find-first',
    create: 'regions/create',
    updateMany: 'regions/update-many',
    softDelete: 'regions/soft-delete',
  },
  leaseResponsibility: {
    list: 'lease-responsibilities/find-all-by-lease',
    create: 'lease-responsibilities/create',
    update: 'lease-responsibilities/update',
    remove: 'lease-responsibilities/remove',
  },
  responsibilityTypes: {
    list: 'lease-responsibilities/find-all-responsibility-types',
  },
  enum: {
    findAll: 'enum/find-all',
  },
  leaseType: {
    list: 'lease-types/find-all',
  },
  leaseAreaType: {
    list: 'lease-area-types/find-all',
  },
  leaseBusinesses: {
    create: 'lease-businesses/create',
    updateMany: 'lease-businesses/update-many',
    delete: 'lease-businesses/delete',
    list: 'lease-businesses/find-all',
    findByActiveClient: 'lease-businesses/find-by-client',
  },
  leaseComp: {
    findAll: 'lease-comp/find-all-by-lease',
    create: 'lease-comp/create-one',
    update: 'lease-comp/update-one',
    delete: 'lease-comp/delete-one',
  },
  leaseSpace: {
    findAll: 'spaces/read',
    create: 'spaces/create',
    update: 'spaces/update',
    delete: 'spaces/delete',
  },
  criticalDates: {
    findAll: 'critical-dates/find-all',
    findAllRawSQL: 'critical-dates/find-all-via-raw-sql',
  },
  costSchedule: {
    create: 'cost-schedules/create',
    update: 'cost-schedules/update',
    remove: 'cost-schedules/remove',
    findByLease: 'cost-schedules/find-by-lease',
    getCategories: 'cost-schedules/get-all-categories-by-client',
    categoriesByLease: 'cost-schedules/get-all-categories',
    getDateRange: 'cost-schedules/get-date-range',
    generateBudget: 'cost-schedules/generate-budget',
  },
  clientCurrencies: {
    findByClient: 'client-currencies/find-by-client',
  },
  currency: {
    getCurrencies: 'currencies/find-all',
  },
  contacts: {
    create: 'contacts/create',
    update: 'contacts/update',
    delete: 'contacts/delete',
    findManyByClient: 'contacts/find-many-by-client',
    findManyWithResourceByClient: 'contacts/find-many-with-resources-by-client',
  },
  contactAssign: {
    getManyByObjectTypeAndResourceIdentifierSelector:
      'contact-assign/find-many-by-contact-object-type-and-resource-identifier',
    assignWithContact: 'contact-assign/create-assigned-contact-with-contact',
    updateWithContact: 'contact-assign/update-assigned-contact-with-contact',
    remove: 'contact-assign/un-assign',
  },
  contactTypes: {
    findAll: 'contact-types/find-all',
    findByClient: 'contact-types/find-by-client',
    create: 'contact-types/create-for-client',
    remove: 'contact-types/remove',
  },
  dashboard: {
    findDraftEditedLeases: 'leases/find-draft-edited-leases',
    leasesDashboardMetrics: 'leases/dashboard',
    findLeasesTotalForCurrentYear: 'leases/find-leases-total-for-current-year',
  },
  documents: {
    getDocumentsByTag: `documents/get-documents-by-tag`,
    getDocumentsByLease: 'documents/get-documents-by-lease',
    getDocumentsByClient: 'documents/get-documents-by-client',
    updateDocument: 'documents/update-document',
    updateDocumentTags: 'documents/update-document-tags',
    deleteDocumentByTag: 'documents/delete-document-by-tag',
    deleteDocumentByPublicId: 'documents/delete-document-by-public-id',
  },
  costCategory: {
    findByClient: 'cost-schedule-category/find-all',
    create: 'cost-schedule-category/create',
    update: 'cost-schedule-category/update',
    delete: 'cost-schedule-category/delete',
  },
  exchangeRates: {
    findByClient: 'client-currencies/find-by-client',
    create: 'client-currencies/create',
    update: 'client-currencies/update',
    remove: 'client-currencies/remove',
  },

  transactions: {
    getManyByClient: 'transaction/get-many-by-client',
    getOne: 'transaction/get-one',
    createOne: 'transaction/create-one',
    updateOne: 'transaction/update-one',
    softDeleteOne: 'transaction/soft-delete-one',
  },
  transactionPhases: {
    getManyByClient: 'transaction-phase/get-many-by-client',
    createOne: 'transaction-phase/create-one',
    restoreOne: 'transaction-phase/restore-one',
    updateMany: 'transaction-phase/update-many',
    softDeleteOne: 'transaction-phase/soft-delete-one',
  },
  transactionTypes: {
    getMany: 'transaction-type/get-many',
  },
  taxSched: {
    getManyByClient: 'tax-sched/get-many-by-client',
  },
  docFile: {
    findDocumentsByActiveClient: 'doc-file/find-documents-by-active-client',
    create: 'doc-file/create',
    read: 'doc-file/read',
    update: 'doc-file/update',
    softDelete: 'doc-file/soft-delete',
  },
  docFileType: {
    getAll: 'doc-file-type/get-all',
    create: 'doc-file-type/create',
    read: 'doc-file-type/read',
    update: 'doc-file-type/update',
    delete: 'doc-file-type/delete',
  },
  accountingCategoryType: {
    findAll: 'accounting-category/find-all',
  },
  invoice: {
    findByActiveClient: 'invoices/find-by-active-client',
    findOne: 'invoices/find-one',
    findByLease: 'invoices/find-by-lease',
    create: 'invoices/create',
    update: 'invoices/update',
    createInvoiceDetail: 'invoices/create-invoice-detail',
    createManyInvoiceDetail: 'invoices/create-many-invoice-detail',
    updateInvoiceDetail: 'invoices/update-invoice-detail',
    updateManyInvoiceDetail: 'invoices/update-many-invoice-detail',
    delete: 'invoices/delete',
    deleteInvoiceDetail: 'invoices/delete-invoice-detail',
  },
};
