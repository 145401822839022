import { UserEntity } from 'entityTypes/User';

import { APP_CONFIG } from './config';

export const INIT_USER: UserEntity = {
  userUuid: '',
  activeClientUuid: '',
  isAuth: false,
  isAdmin: false,
  oktaUserId: '',
  userName: '',
  firstName: '',
  lastName: '',
  email: '',
  selectedItem: undefined,
  clientLogo: `${APP_CONFIG.basePath}/images/clients/client-logo-placeholder.svg`,
  accessToken: '',
  signInRetries: 0,
  oktaClientId: '',
  userClients: [],
  tableSettings: [],
  selectedLease: undefined,
  selectedProperty: undefined,
  createdAt: '',
};

export const initUser = () => INIT_USER;
